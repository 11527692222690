import { isIOS } from 'react-device-detect';
import { useLocation, useOutletContext } from 'react-router-dom';
import i18next from 'i18next';
import moment, { Moment } from 'moment';
import { Route } from 'src/constants';
import { iconUrl } from 'src/constants/common';
import { ContextType, Distination, RouterConfigItem, SelectOption } from 'src/types';
import { Passenger } from 'src/types/booking';
import { RowPassenger } from 'src/types/passengers';

const routerHeaderConfig: RouterConfigItem[] = [
    {
        path: Route.MAIN,
        headerTitle: null,
        isShowenFAQ: true,
    },
    {
        path: Route.FAQ,
        headerTitle: i18next.t('pages.faq.title'),
        isShowenFAQ: false,
    },
    {
        path: Route.SEARCH_DATES,
        headerTitle: i18next.t('pages.dates.title'),
        isShowenFAQ: false,
    },
    {
        path: Route.SEARCH_DIRECTION,
        headerTitle: i18next.t('shared.direction.from'),
        isShowenFAQ: false,
    },
    {
        path: Route.SEARCH_DETAILS,
        headerTitle: i18next.t('pages.details.title'),
        isShowenFAQ: false,
    },
    {
        path: Route.PROFILE,
        headerTitle: i18next.t('pages.profile.title'),
        isShowenFAQ: true,
    },
    {
        path: Route.HISTORY,
        headerTitle: i18next.t('pages.details.title'),
        isShowenFAQ: true,
    },
    {
        path: Route.PASSENGERS,
        headerTitle: i18next.t('pages.profile.passengers'),
        isShowenFAQ: false,
    },
    {
        path: Route.PASSENGERS_FORM_VIEW,
        headerTitle: i18next.t('pages.profile.passengersForm'),
        isShowenFAQ: false,
    },
    {
        path: Route.PASSENGERS_FORM_ADD,
        headerTitle: i18next.t('pages.profile.passengersForm'),
        isShowenFAQ: false,
    },
    {
        path: Route.TICKET_DETAILS,
        headerTitle: i18next.t('pages.ticketDetails.title'),
        isShowenFAQ: true,
    },
    {
        path: Route.BOOKING,
        headerTitle: '',
        isShowenFAQ: true,
    },
    {
        path: Route.ACTIVE_TICKET,
        headerTitle: '',
        isShowenFAQ: true,
    },
    {
        path: Route.BOOKED_TICKET,
        headerTitle: '',
        isShowenFAQ: true,
    },
];

export const getFormatedDate = (date: Moment | Date, dateFormat: string) => {
    return moment(date).format(dateFormat);
};

export const convertInputDate = (inputDate: string) => {
    return moment(inputDate, 'DD / MM / YYYY').format('YYYY-MM-DD');
};

export const convertPayloadDate = (inputDate: string) => {
    return moment(inputDate, 'YYYY-MM-DD').format('DD / MM / YYYY');
};

export const snakeToCamelCase = (obj: Record<string, any>) => {
    const newObj: Record<string, any> = Array.isArray(obj) ? [] : {};

    for (const d of Object.keys(obj)) {
        const camelCase = d.replace(/(_\w)/g, function (m) {
            return m[1].toUpperCase();
        });

        newObj[camelCase] =
            typeof obj[d] === 'object' && obj[d] !== null ? snakeToCamelCase(obj[d]) : obj[d];
    }

    return newObj;
};

export const getHeaderInfo = () => {
    const { pathname } = useLocation();

    return (
        routerHeaderConfig.find((item) => item.path === pathname) || {
            headerTitle: '',
            isShowenFAQ: false,
            path: '',
        }
    );
};

export const getDirectionFullName = (distination: Distination | null) => {
    if (distination === null) return '';

    return `${distination.name}, (${distination.code})`;
};

export const getIconByCode = (code: string) => {
    return `${iconUrl}/${code}.png`;
};

export const useHeaderContext = () => useOutletContext<ContextType>();

export const genders = [
    {
        value: 'male',
        id: 'MR',
    } as const,
    {
        value: 'female',
        id: 'MRS',
    } as const,
];

export const prepareDefaultValues = (
    rowData: RowPassenger | Passenger,
    countries: SelectOption[],
    documents: SelectOption[],
) => {
    const formGender = genders.find((gender) => gender.id === rowData.gender);
    const formDocCountry = countries.find((country) => country.id === rowData.doc_country);
    const formDocType = documents.find((document) => document.id === rowData.doc_type);

    return {
        ...rowData,
        birthday: convertPayloadDate(rowData.birthday),
        doc_expiration: convertPayloadDate(rowData.doc_expiration),
        gender: formGender,
        doc_country: formDocCountry,
        doc_type: formDocType,
    };
};

export const closeWebView = () => {
    if (isIOS) {
        // @ts-ignore
        window.ExternalVendorJsInterface.goBack();
    } else {
        // @ts-ignore
        window.ExternalVendorJsInterface.finishScreen();
    }
};
