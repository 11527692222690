import { AppStore } from 'src/store/app';

import { default as AgeTypeADTDark } from './age-type-adt-dark.svg';
import { default as AgeTypeADTLight } from './age-type-adt-light.svg';
import { default as AgeTypeCHDDark } from './age-type-chd-dark.svg';
import { default as AgeTypeCHDLight } from './age-type-chd-light.svg';
import { default as AgeTypeINFDark } from './age-type-inf-dark.svg';
import { default as AgeTypeINFLight } from './age-type-inf-light.svg';
import { default as AirCompanyPlaceholder } from './air-company-placeholder.svg';
import { default as ArrowBackDark } from './arrow-back-dark.svg';
import { default as ArrowBackLight } from './arrow-back-light.svg';
import { default as ArrowDirectionDark } from './arrow-direction-dark.svg';
import { default as ArrowDirectionLight } from './arrow-direction-light.svg';
import { default as ArrowRightLight } from './arrow-right-light.svg';
import { default as Baggage } from './baggage.svg';
import { default as BaggagePaidDark } from './baggage-paid-dark.svg';
import { default as BaggagePaidLight } from './baggage-paid-light.svg';
import { default as BestSeatsDark } from './best-seats-dark.svg';
import { default as BestSeatsLight } from './best-seats-light.svg';
import { default as BusinessDark } from './business-dark.svg';
import { default as BusinessLight } from './business-light.svg';
import { default as CalendarDark } from './calendar-dark.svg';
import { default as CalendarLight } from './calendar-light.svg';
import { default as CallIcon } from './call-icon.png';
import { default as CancelDark } from './cancel-dark.svg';
import { default as CancelLight } from './cancel-light.svg';
import { default as Check } from './check.svg';
import { default as Checked } from './checked.svg';
import { default as Chevron } from './chevron.svg';
import { default as ChevronRight } from './chevron-right.svg';
import { default as Close } from './close.svg';
import { default as DeleteDark } from './delete-dark.svg';
import { default as DeleteLight } from './delete-light.svg';
import { default as DownloadDark } from './download-dark.svg';
import { default as DownloadLight } from './download-light.svg';
import { default as EditTicketDark } from './edit-ticket-dark.svg';
import { default as EditTicketLight } from './edit-ticket-light.svg';
import { default as EditTicketPaidDark } from './edit-ticket-paid-dark.svg';
import { default as EditTicketPaidLight } from './edit-ticket-paid-light.svg';
import { default as Info } from './info.svg';
import { default as Luggage } from './luggage.svg';
import { default as LuggageDark } from './luggage-dark.svg';
import { default as LuggageLight } from './luggage-light.svg';
import { default as MealFreeDark } from './meal-free-dark.svg';
import { default as MealFreeLight } from './meal-free-light.svg';
import { default as MealPaidDark } from './meal-paid-dark.svg';
import { default as MealPaidLight } from './meal-paid-light.svg';
import { default as MealSpecialDark } from './meal-special-dark.svg';
import { default as MealSpecialLight } from './meal-special-light.svg';
import { default as NoReturnTicketDark } from './no-return-ticket-dark.svg';
import { default as NoReturnTicketLight } from './no-return-ticket-light.svg';
import { default as NotFound } from './not-found.svg';
import { default as PlaneDownDark } from './plane-down-dark.svg';
import { default as PlaneDownLight } from './plane-down-light.svg';
import { default as PlaneTopDark } from './plane-top-dark.svg';
import { default as PlaneTopLight } from './plane-top-light.svg';
import { default as PlaneUpDark } from './plane-up-dark.svg';
import { default as PlaneUpLight } from './plane-up-light.svg';
import { default as ProfileTwoUserDark } from './profile-two-user-dark.svg';
import { default as ProfileTwoUserLight } from './profile-two-user-light.svg';
import { default as QuestionMarkDark } from './question-mark-dark.svg';
import { default as QuestionMarkLight } from './question-mark-light.svg';
import { default as Recommendations } from './recommendations.svg';
import { default as ReturnTicketDark } from './return-ticket-dark.svg';
import { default as ReturnTicketLight } from './return-ticket-light.svg';
import { default as RoundArrows } from './round-arrows.svg';
import { default as RoundArrowsLight } from './round-arrows-light.svg';
import { default as TicketChangeDark } from './ticket-change-dark.svg';
import { default as TicketChangeLight } from './ticket-change-light.svg';
import { default as TimerDark } from './timer-dark.svg';
import { default as TimerLight } from './timer-light.svg';
import { default as User } from './user.svg';
import { default as UserEmptyDark } from './user-empty-dark.svg';
import { default as UserEmptyLight } from './user-empty-light.svg';
import { default as VipDark } from './vip-dark.svg';
import { default as VipLight } from './vip-light.svg';
import { default as WhatsappIcon } from './whatsapp-icon.png';

const theme = AppStore.getState().theme;

const isLightTheme =
    // @ts-expect-error: no index.d.ts
    'ExternalVendorJsInterface' in window ? window.ExternalVendorJsInterface.isThemeLight() === 'true' : theme === 'light';

const AgeTypeADT = isLightTheme ? AgeTypeADTLight : AgeTypeADTDark;
const AgeTypeCHD = isLightTheme ? AgeTypeCHDLight : AgeTypeCHDDark;
const AgeTypeINF = isLightTheme ? AgeTypeINFLight : AgeTypeINFDark;
const QuestionMark = isLightTheme ? QuestionMarkLight : QuestionMarkDark;
const PlaneTop = isLightTheme ? PlaneTopLight : PlaneTopDark;
const PlaneDown = isLightTheme ? PlaneDownLight : PlaneDownDark;
const PlaneUp = isLightTheme ? PlaneUpLight : PlaneUpDark;
const ProfileTwoUser = isLightTheme ? ProfileTwoUserLight : ProfileTwoUserDark;
const LuggageDetails = isLightTheme ? LuggageLight : LuggageDark;
const ArrowBack = isLightTheme ? ArrowBackLight : ArrowBackDark;
const ArrowDirection = isLightTheme ? ArrowDirectionLight : ArrowDirectionDark;
const Download = isLightTheme ? DownloadLight : DownloadDark;
const MealFree = isLightTheme ? MealFreeLight : MealFreeDark;
const BaggagePaid = isLightTheme ? BaggagePaidLight : BaggagePaidDark;
const EditTicketPaid = isLightTheme ? EditTicketPaidLight : EditTicketPaidDark;
const NoReturnTicket = isLightTheme ? NoReturnTicketLight : NoReturnTicketDark;
const MealSpecial = isLightTheme ? MealSpecialLight : MealSpecialDark;
const EditTicket = isLightTheme ? EditTicketLight : EditTicketDark;
const ReturnTicket = isLightTheme ? ReturnTicketLight : ReturnTicketDark;
const BestSeats = isLightTheme ? BestSeatsLight : BestSeatsDark;
const Business = isLightTheme ? BusinessLight : BusinessDark;
const Timer = isLightTheme ? TimerLight : TimerDark;
const Vip = isLightTheme ? VipLight : VipDark;
const MealPaid = isLightTheme ? MealPaidLight : MealPaidDark;
const TicketChange = isLightTheme ? TicketChangeLight : TicketChangeDark;
const Cancel = isLightTheme ? CancelLight : CancelDark;
const UserEmpty = isLightTheme ? UserEmptyLight : UserEmptyDark;
const Calendar = isLightTheme ? CalendarLight : CalendarDark;
const Delete = isLightTheme ? DeleteLight : DeleteDark;

export {
    AgeTypeADT,
    AgeTypeCHD,
    AgeTypeINF,
    WhatsappIcon,
    QuestionMark,
    ArrowRightLight,
    PlaneTop,
    PlaneDown,
    PlaneUp,
    ProfileTwoUser,
    RoundArrowsLight,
    Calendar,
    RoundArrows,
    Close,
    Baggage,
    Luggage,
    LuggageDetails,
    AirCompanyPlaceholder,
    ArrowBack,
    ArrowBackLight,
    ArrowBackDark,
    ArrowDirection,
    Check,
    NotFound,
    Chevron,
    Checked,
    Download,
    User,
    UserEmpty,
    Recommendations,
    MealFree,
    BaggagePaid,
    EditTicketPaid,
    NoReturnTicket,
    MealSpecial,
    EditTicket,
    ReturnTicket,
    BestSeats,
    Business,
    Timer,
    Vip,
    MealPaid,
    TicketChange,
    Cancel,
    ChevronRight,
    CallIcon,
    Info,
    Delete,
};
