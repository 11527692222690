import { ReactNode, useEffect } from 'react';
import { Nullable } from 'src/types';
import { create } from 'zustand';

type HeaderProps = {
    title: ReactNode | string;
    goBackClickHandler: () => void;
    rightAddon: ReactNode;
    isExternalFaqShown: boolean;
};

type ButtonProps = {
    handler: () => void;
    text: ReactNode | string;
    disabled: boolean;
    loading: boolean;
    hidden: boolean;
};

type ContentProps = {
    isFullHeight: boolean;
    withoutPaddings: boolean;
};

type State = {
    header: Nullable<Partial<HeaderProps>>;
    button: Nullable<Partial<ButtonProps>>;
    bottomAddon: Nullable<ReactNode>;
    content: Nullable<Partial<ContentProps>>;
};

type CallbackState = {
    setLayoutState: (state: Partial<State>) => void;
    updateLayoutState: (state: Partial<State>) => void;
};

const initialState: State = {
    header: null,
    button: null,
    bottomAddon: null,
    content: {
        isFullHeight: false,
        withoutPaddings: false,
    },
};

const layoutContext = create<State & CallbackState>((set) => ({
    ...initialState,
    setLayoutState: (state) =>
        set(() => ({
            ...initialState,
            ...state,
        })),
    updateLayoutState: (updatedState) =>
        set((state) => ({
            ...state,
            header: { ...state.header, ...updatedState.header },
            button: { ...state.button, ...updatedState.button },
            bottomAddon: updatedState.bottomAddon || state.bottomAddon,
            content: { ...state.content, ...updatedState.content },
        })),
}));

export const getLayoutState = () =>
    layoutContext((state) => ({
        header: state.header,
        button: state.button,
        bottomAddon: state.bottomAddon,
        content: state.content,
    }));

export const useSetLayoutState = () => layoutContext((state) => state.setLayoutState);
export const useUpdateLayoutState = () => layoutContext((state) => state.updateLayoutState);

export const useSetLayoutStateOnMount = (state: Partial<State>) => {
    const setLayoutState = useSetLayoutState();

    useEffect(() => {
        setLayoutState(state);
    }, []);
};
